import i18n from '@/i18n'
import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import isEmpty from 'lodash/isEmpty'

import { BASE_URL } from '@/variables'

import {
  createWarningNotification,
  createErrorNotification
} from '@/lib/unnotificationsqueue'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import CustomView from '@/lib/uncustomui/components/CustomView'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation'
import ProductsFilter from '@/apps/dido/components/filters/ProductsFilter/ProductsFilter.vue'

const RELATION_TYPE_PROMOTION = 2

@Component({
  props: {
    value: {
      type: Object,
      default: {}
    },
    filterStoreModule: {
      type: String,
      default: 'hierarchiesFilterSelectorStore'
    }
  },
  components: { CrudTable, CustomView, DialogConfirmation },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),
    ...mapState('productsFilter', ['selectedProduct'])
  }
})

class ProductReplacementsTable extends Vue {
  storeModule = 'productReplacement'
  productEndpoint = '/products-list/'
  customOptions = { 'sortBy': ['id'], 'sortDesc': [true] }
  showProductReplacementForm = false
  isEditingMode = false
  showDialogConfirmation = false
  selectedProductReplacementLine = {}
  currentItem = this.value
  customComponentCatalog = {
    'ProductsFilter': ProductsFilter
  }
  options = {
    context: {
      serverUrl: BASE_URL,
      productId: this.updatedId,
      reload: true
    }
  }
  form = {}
  loading = false
  confirmOptions = {
    titleDialog: i18n.tc('general.delete'),
    textDialog: i18n.tc('general.deleteModalMessage'),
    confirmText: i18n.tc('general.yes'),
    confirmColor: 'red'
  }

  get updatedId () {
    return this.currentItem.id
  }

  onAddProductReplacement () {
    this.cleanForm().then(() => {
      this.$store.commit('productsFilter/setHierarchyListEndpoint', this.productEndpoint)
      this.$store.commit('productsFilter/setIsProductReplacement', true)
      this.$store.commit('productsFilter/setShowTable', true)
      this.showProductReplacementForm = true
    })
  }

  async editProductReplacement (item) {
    this.selectedProductReplacementLine = item
    this.isEditingMode = true
    await this.$store.commit('productsFilter/setSelectedProduct', { ...item.product })
    this.form = {
      relation_type: item.relation_type,
      old_product: item.old_product,
      start_date: item.start_date,
      end_date: item.end_date,
      prop__boost_coefficient: item.props.boost_coefficient,
      prop__cannibalization_coefficient: item.props.cannibalization_coefficient
    }
    this.isEditingMode = true
    this.showProductReplacementForm = true
  }

  async saveForm () {
    if (this.form.relation_type === RELATION_TYPE_PROMOTION) {
      await this.$store.commit('productsFilter/setSelectedProduct', { ...this.currentItem })
    }
    if (isEmpty(this.selectedProduct)) {
      await this.$store.dispatch('addNotification', createWarningNotification(this.$t('promos.selectProduct')))
    } else if (this.$refs.form.validate()) {
      try {
        this.loading = true
        this.form.old_product = this.selectedProduct.id
        if (this.isEditingMode) {
          this.selectedProductReplacementLine = { new_product: this.updatedId, ...this.form }
          await this.$store.dispatch('productReplacement/putItemWithBody', {
            pk: this.selectedProductReplacementLine.id, body: this.selectedProductReplacementLine
          })
        } else {
          let newProductReplacement = { new_product: this.updatedId, ...this.form }
          await this.$store.dispatch('productReplacement/createItemWithBody', newProductReplacement)
        }
        await this.cleanForm()
        await this.$store.dispatch('productReplacement/reloadItemList')
      } catch (error) {
        this.loading = false
      }
    }
  }

  formInput (items) {
    const currentRelationType = this.form.relation_type
    const isTypeChange = currentRelationType !== items.relation_type

    if (isTypeChange) {
      if (items.relation_type === RELATION_TYPE_PROMOTION) {
        this.$store.commit('productsFilter/setSelectedProduct', { ...this.currentItem })
        this.$store.commit('productsFilter/setShowTable', false)
      } else if (items.relation_type !== RELATION_TYPE_PROMOTION && typeof currentRelationType !== 'undefined') {
        this.$store.commit('productsFilter/setSelectedProduct', {})
        this.$store.commit('productsFilter/setShowTable', true)
      }
    }

    this.form = { ...items }
  }

  /**
   * Clean form and related values when form dialog close
   * @returns {Promise<void>}
   */
  async cleanForm () {
    await this.$store.commit('productsFilter/setSelectedProduct', {})
    this.selectedProductReplacementLine = {}
    this.showProductReplacementForm = false
    this.isEditingMode = false
    this.form = {}
    this.loading = false
  }

  /**
   * Delete selected product order and reload Crudtable
   * @returns {Promise<void>}
   */
  async onDeleteProductReplacement () {
    this.loading = true
    try {
      await this.$store.dispatch('productReplacement/deleteItemThrow', this.selectedProductReplacementLine.id)
      await this.$store.dispatch('productReplacement/reloadItemList')
    } catch {
      await this.$store.dispatch('addNotification', createErrorNotification(this.$t('general.basicError')))
    } finally {
      this.loading = false
      this.showDialogConfirmation = false
    }
  }

  deleteConfirmation (item) {
    this.selectedProductReplacementLine = item
    this.showDialogConfirmation = true
  }
}

export default ProductReplacementsTable
