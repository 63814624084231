<template>
  <v-container class="d-flex flex-column">
    <BreadcrumbsField :items="itemsBreadcrumbs" class="align-self-end"/>
    <v-form @submit.prevent="saveForm" ref="form">
      <CustomView
        view-name="product_detail"
        :value="currentItem"
        @input="updateItem($event)"
        :options="options"
        :custom-component-catalog="customComponentCatalog"
      />
      <v-row class="mx-2">
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loadingSave"
          :disabled="loadingSave"
          @click="loader = 'loadingSave'">
          <v-icon left dark>fa-save</v-icon>
          {{ $t('general.save') }}
        </v-btn>
        <v-btn
          class="mx-2"
          color="primary"
          :loading="loadingSaveAndContinue"
          :disabled="loadingSaveAndContinue"
          @click="loader = 'loadingSaveAndContinue'">
          <v-icon left dark>fa-save</v-icon>
          {{ $t('customActions.saveAndContinue') }}
        </v-btn>
      </v-row>
      <PrettyAlert :text="textAlert" v-model="showAlert"/>
    </v-form>
  </v-container>
</template>

<script>
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import CustomView from '@/lib/uncustomui/components/CustomView'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import ProductSubstitution from '@/apps/core/components/ProductSubstitution/ProductSubstitution'
import { BASE_URL, RESPONSE_LEVEL } from '@/variables'
import { mapState, mapActions } from 'vuex'
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification
} from '@/lib/unnotificationsqueue'
import ProductReplacementsTable from '../tables/ProductReplacementsTable/ProductReplacementsTable'

export default {
  name: 'ProductForm',
  components: { PrettyAlert, CustomView, BreadcrumbsField },
  data () {
    return {
      itemsBreadcrumbs: [
        {
          text: this.$t('products.list'),
          disabled: false,
          name: 'ProductList'
        },
        {
          text: this.$t('general.product'),
          disabled: true
        }
      ],
      loader: null,
      loadingSave: false,
      loadingSaveAndContinue: false,
      options: {
        context: {
          serverUrl: BASE_URL
        }
      },
      productId: this.$route.params.id,
      textAlert: '',
      showAlert: false,
      customComponentCatalog: {
        'ProductSubstitution': ProductSubstitution,
        'ProductReplacementsTable': ProductReplacementsTable
      }
    }
  },
  created () {
    if (this.productId > 0) {
      this.getItem(this.productId)
    }
  },
  computed: {
    ...mapState('products', ['currentItem'])
  },
  methods: {
    ...mapActions('products', ['getItem', 'patchItem', 'updateCurrentItem']),
    ...mapActions({ addNotification: 'addNotification' }),
    saveForm (redirect) {
      this.patchItem(this.productId)
        .then(response => {
          if (redirect) {
            this.$router.push({ name: 'ProductList' })
          } else {
            this.displayNotification(response)
            this.stopLoadingButton()
          }
        })
        .catch(error => {
          this.displayNotification(error)
          this.stopLoadingButton()
        })
    },
    displayNotification (message) {
      if (message.level === RESPONSE_LEVEL.SUCCESS) {
        this.addNotification(createSuccessNotification(message.message))
      } else if (message.level === RESPONSE_LEVEL.WARNING) {
        this.addNotification(createWarningNotification(message.message))
      } else if (message.level === RESPONSE_LEVEL.ERROR) {
        this.addNotification(createErrorNotification(message.message))
      } else {
        this.addNotification(createErrorNotification(this.$t('general.errorSomethingHappenedOptional')))
      }
    },
    stopLoadingButton () {
      this.loadingSave = false
      this.loadingSaveAndContinue = false
    },
    updateItem (items) {
      this.updateCurrentItem({ ...this.currentItem, ...items })
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      if (l === 'loadingSave') {
        this.saveForm(true)
      } else if (l === 'loadingSaveAndContinue') {
        this.saveForm(false)
      }

      this.loader = null
    }
  }
}
</script>

<style scoped>

</style>
