import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'

import CrudTable from '@/lib/uncrudtable/components/CrudTable'

@Component({
  props: {
    customStoreModule: {
      type: String,
      default: 'hierarchiesFilterSelectorStore'
    }
  },
  components: {
    CrudTable
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      selectedProduct: function (state) { return state[this.storeModule].selectedProduct },
      showTable: function (state) { return state[this.storeModule].showTable }
    }),
    ...mapState('hierarchiesFilterSelectorStore', { productsList: 'filtersList', loadingFiltersList: 'loadingFiltersList' })
  }
})

class ProductsFilter extends Vue {
  storeModule = 'productsFilter'
  headers = [
    { text: 'id', value: 'ean' },
    { text: 'Name', value: 'name' }
  ]
  search = ''
  options = {}

  /**
   * Returns true when a product is selected, to handle the toggle button's state
   * @returns {boolean}
   */
  get isProductSelected () {
    return !!this.selectedProduct.id
  }

  /**
   * Returns the toggle button's text according to table state
   * @returns {string}
   */
  get toggleButtonText () {
    return this.showTable ? this.$t('general.close') : this.$t('general.change')
  }

  async created () {
    await this.$store.commit(`${this.storeModule}/setFiltersStoreModule`, this.customStoreModule)
  }

  async mounted () {
    this.headers.unshift({ text: '', value: 'selected', width: '50px' })
  }

  /**
   * Event handler for selecting a product: sets it to the store module
   * @param selected {Object} selected product from clicked row
   */
  onSelectProduct (selected) {
    this.$store.commit(`${this.storeModule}/setSelectedProduct`, selected)
    this.$store.commit(`${this.storeModule}/setShowTable`, false)
  }

  /**
   * Event handler for button: Toggles the table's rendering
   */
  toggleTable () {
    this.$store.commit(`${this.storeModule}/setShowTable`, !this.showTable)
  }
}

export default ProductsFilter
