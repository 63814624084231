import DatePickerInField from '@/apps/core/components/forms/DateRangePickerInField/DateRangePickerInField'
import apiClient from '@/lib/unlogin/store/apiclient'

export default {
  name: 'ProductSubstitution',
  components: {
    DatePickerInField
  },
  props: {
    value: Object,
    description: 'The product object'
  },
  data () {
    return {
      showDialog: false,
      selectedDate: null,
      selectedProduct: null,
      productsList: null,
      isLoading: false,
      productReplacement: {
        old_product: null,
        new_product: null,
        start_date: null
      },
      replacedByProduct: null,
      replacementToProductList: [],
      agreementsAffected: null,
      routeName: false
    }
  },
  /**
   * Used to get the name of the app, to redirect Juno/Titan.
   */
  beforeMount () {
    this.routeName = this.$router.currentRoute.name === 'JunoProductDetail' ? 'JunoProductDetail' : 'ProductDetail'
  },
  async mounted () {
    this.replacedByProduct = await this.product.replaced_by
    this.replacementToProductList = await this.product.replace_to
    await this.getAgreementsAffectedCount()
  },
  computed: {
    datePickerSelected: {
      /**
   * The selected date.
   * @returns {null}
   */
      get () {
        return this.selectedDate
      },
      /**
   * Set the selected date with the date selected on DatepickerInField component.
   * @param dateSelected
   */
      set (dateSelected) {
        this.selectedDate = dateSelected
      }
    },
    /**
   * The product name.
   * @returns {String}
   */
    product () {
      return this.value
    },
    /**
   * The list of products to complete the dropdown
   * @returns {null}
   */
    productsForSubstitution () {
      if (this.productsList) {
        return this.productsList
      } else {
        this.isLoading = true
        apiClient
          .get('/active-products-choices/')
          .then(response => {
            let tmpList = []
            response.data.forEach(p => {
              tmpList.push({
                sku: p.sku, text: p.sku + ' - ' + p.text, value: p.value
              })
            })
            this.productsList = tmpList
            this.isLoading = false
          })
      }
    },
    /**
   * Disable de save button in case there is not information in the inputs
   * @returns {boolean}
   */
    disableSaveButton () {
      return !(this.selectedDate && this.selectedProduct)
    }
  },
  methods: {
    /**
   * Open/close dialog.
   */
    toggleDialog () {
      this.showDialog = !this.showDialog
    },
    /**
   * Rest the dialog form.
   */
    resetFrom () {
      this.selectedProduct = null
      this.selectedDate = null
      this.toggleDialog()
    },
    /**
   * Date parsed in DD/MM/YYYY to show in the block.
   * @returns {string}
   */
    parseDate (dateToParse) {
      const date = new Date(dateToParse).toISOString().substr(0, 10)
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
   * Send the object who is going to replace the product.
   */
    replaceProduct () {
      this.productReplacement = {
        old_product: this.$props.value.id,
        new_product: this.selectedProduct.value,
        start_date: this.selectedDate
      }
      apiClient
        .post('/product-replacement/', this.productReplacement)
        .then(response => {
          this.getProductReplacement()
          this.isLoading = false
          this.toggleDialog()
        })
    },
    /**
   * Total count of the agreements affected based in the current product.
   */
    getAgreementsAffectedCount () {
      apiClient
        .get(`/product_replacements_agreements_count/${this.product.id}`)
        .then(response => {
          this.agreementsAffected = response.data.count
        })
    },
    /**
   * Get the replacement product, this function its used after substitute de product.
   */
    getProductReplacement () {
      apiClient
        .get(`/products-list/${this.product.id}/`)
        .then(response => {
          this.replacedByProduct = response.data.replaced_by
          this.replacementToProductList = response.data.replace_to
        })
    },
    /**
   * Function to iterate by every product in the autocomplete component and return the object filtered.
   * @param item (Object) Product object
   * @param queryText (string) The search text
   * @param itemText (string) Product text
   * @returns {boolean} Whether the promo is filtered
   */
    productsFiltering (item, queryText, itemText) {
      return (
        item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      item.sku.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }
  }
}
